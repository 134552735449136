import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { wait } from 'components/admin/services/utilities/wait';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';


@autoinject()
export class TneAsyncResolver {
    private apiBaseUrl: string;
    private defaultDelay = 1000;

    constructor(
		private httpClient: HttpClient,
        private config: EnvironmentConfiguration) {

        this.apiBaseUrl = this.config.env.ApiBaseUrl;
    }


    /**
     * Polls TNE and returns when a job has finished 
     * @param jobId 
     * @param callback 
     * @param delay optional delay in milliseconds (defaults to one second)
     */
    async execute(jobId: number, callback: ProgressCallback, delay = this.defaultDelay): Promise<IJobInfoData> {
        return this.pollTne(jobId, delay, callback);
    }

    /**
     * Polls TNE until a job has finished and downloads the result as <T>
     * Also gives progress notifications to provided callback
     * @param jobId 
     * @param callback 
     * @param delay optional delay in milliseconds
     */
    async executeWithResult<T>(jobId: number, callback: ProgressCallback, delay = this.defaultDelay): Promise<T> {
        const pollResult = await this.pollTne(jobId, delay, callback);
        
        return await this.getData<T>(pollResult);
    }

	async executeGetJobInfo(jobId: number){
		
		return await this.checkProgress(jobId)
	}

    private async pollTne(jobId: number, delay: number, callback?: ProgressCallback): Promise<IJobInfoData> {
        const promise = new Promise<IJobInfoData>((resolve, reject) => {
            const pollerThread = async (id: number): Promise<IJobInfoData> => {
                const res = await this.checkProgress(id);
                
                if (callback) {
                    callback(res);
                }

                if (this.jobIsDone(res)) {
                    return res;
                }
                else {
                    return wait(delay)
                    .then(() => {
                        return pollerThread(id);
                    })
                }
            };
    
            pollerThread(jobId)
            .then((res) => {
                if (res.Status == JobStatus.Completed) {
                    resolve(res);
                }
                else {
                    reject(`Job did not complete with the following JobStatus: ${res.Status}`);
                }
            })
            .catch((reason) => {
                reject(reason);
            })
        });

        return promise;
    }

    private async checkProgress(jobId: number): Promise<IJobInfoData> {
        const url = `${this.apiBaseUrl}/job/status?id=${jobId}`;
        const response = await this.httpClient.fetch(url);
        
        return response.json();
    }

    private async getData<T>(workDef: IJobInfoData): Promise<T> {
        throw Error("Not Implemented");

        // const url = `${this.apiBaseUrl}/job/download/${workDef.filePath}`;
        // const response = await this.httpClient.fetch(url);

        // return response.json();
    }

    private jobIsDone(jobInfo: IJobInfoData): boolean {
        const completedJobStatuses = [
            JobStatus.Canceled,
            JobStatus.Completed,
            JobStatus.Failed,
            JobStatus.Ignored
        ];

        return completedJobStatuses.indexOf(jobInfo.Status) > -1;
    }
}

export enum JobStatus {
    Undefined = 0,
    NotStarted = 1,
    InProgress = 2,
    CancelRequested = 3,
    Canceled = 4,
    Completed = 5,
    Failed = 6,
    Waiting = 7,
    Ignored = 8
}

export interface IJobInfoData {
    JobId: number;
    JobDefinitionId: number;
    ProgressText: string;
    Progress?: number;
    Status: JobStatus;
	LastUpdated: string;
}

export type ProgressCallback = (def: IJobInfoData) => any;