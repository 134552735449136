import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { ToDoStatus } from './models/to-do-status.model';
import { UnvalidFeature } from './models/unvalid-feature.model';
import { UnvalidFeaturesList } from './models/unvalid-feature-list.model';
import { LinkSequenceFeature } from './models/link-sequence-feature.model';
import { GetLinkSequenceFeatureRequest } from './models/get-link-sequence-feature-request.model';
import { ToDoList } from './models/to-do-list.model';

@autoinject()
export class ToDosService {

    private apiBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private config: EnvironmentConfiguration) {
        this.apiBaseUrl = config.env.ApiBaseUrl;
    }

    async getToDoList(): Promise<ToDoList> {
        const url = `${this.apiBaseUrl}/ToDo/GetToDoList`;

        return this.httpClient.fetch(url)
            .then(response => response.json());
    }

    async getIsListUpdated(lastChangeDate: string): Promise<boolean> {
        const url = `${this.apiBaseUrl}/ToDo/IsListUpdated?compareDate=${lastChangeDate}`;
        let ret = false;
        await this.httpClient.fetch(url)
            .then(response => response.json())
            .then((data: boolean) => {
                ret = data;
            });

        return ret;
    }

    async getLinkSequenceFeature(request: GetLinkSequenceFeatureRequest): Promise<LinkSequenceFeature[]> {
        const url = `${this.apiBaseUrl}/ToDo/GetLinkSequenceFeature`;
        const config = {
            method: "POST",
            body: JSON.stringify(request),
            headers: { 'Content-Type': 'application/json' }
        };
        return this.httpClient.fetch(url, config).then(x => x.json());
	}
	
	async GetNoValidFeatures(viewDate: string): Promise<UnvalidFeaturesList> {
        const url = `${this.apiBaseUrl}/ToDo/GetNoValidFeatures?viewDate=${viewDate}`;
	
        return this.httpClient.fetch(url)
            .then(response => response.json());
	}
	
	async GetNoValidFeaturesFromCheckedOutCatalogue(featureTypeId: number, featureOid: string): Promise<UnvalidFeature> {
        const url = `${this.apiBaseUrl}/ToDo/GetUnvalidFeatureFromCheckOutCatalogue?featureTypeId=${featureTypeId}&featureOid=${featureOid}`;
		
        return this.httpClient.fetch(url).then(x => x.json());
    }

    async updateStatus(featureOid: string, status: ToDoStatus): Promise<boolean> {
        const url = `${this.apiBaseUrl}/ToDo/UpdateToDoStatus?featureOid=${featureOid}&status=${status}`;
        const config = {
            method: "PUT",
            headers : {'Content-Type': 'application/json'}
        };
        
        return this.httpClient.fetch(url, config).then(x => x.json());
	}
}
