import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';

import { RouteType } from 'components/admin/admin-constants/index';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { IFacitRoute } from './models/facit-route.interface';
import { IJobResponse } from './models/job-response.interface';
import { IRoute } from './models/route-interface';
import { ICheckOutRequest } from './models/checkout-request.interface';
import { INetworkReferencesWithAction } from './models/network-references-with-action.interface';
import { IRekRoute } from './models/rek-route.interface';
import { ITPRoute } from './models/tp-route.interface';
import { ITimberRoute } from './models/timber-route.interface';
import { Direction } from './models/direction.enum';

@autoinject()
export class AdminRouteService {
    private apiBaseUrl: string;

    constructor(
		private httpClient: HttpClient,
        private config: EnvironmentConfiguration) {

		this.apiBaseUrl = config.env.ApiBaseUrl;
    }

    async saveRoute(route: IRoute): Promise<IJobResponse> {
        const url = `${this.apiBaseUrl}/adminroute/save`;
        const config = {
            method: "POST",
            body: JSON.stringify(route),
            headers : {'Content-Type': 'application/json'}
        };
        
        return this.httpClient.fetch(url, config).then(x => x.json());
    }

    async checkInRoute(route: IRoute): Promise<number> {
        const url = `${this.apiBaseUrl}/adminroute/checkin`;
        const config = {
            method: "POST",
            body: JSON.stringify(route),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
    }

    async setStatusToActiveRoute(route: IRoute): Promise<number> {
        const url = `${this.apiBaseUrl}/adminroute/updatestatustoactive`;
        const config = {
            method: "POST",
            body: JSON.stringify(route),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
    }

    async checkOutRoute(checkOutRequest: ICheckOutRequest): Promise<IJobResponse> {
        const url = `${this.apiBaseUrl}/adminroute/checkout`;
        const config = {
            method: "POST",
            body: JSON.stringify(checkOutRequest),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
    }

    async getCheckOuts(viewDate:string): Promise<IRoute[]> {
        const url = `${this.apiBaseUrl}/adminroute/checkoutlist?viewDate=${viewDate}`;

        return this.httpClient.fetch(url)
			.then(response => response.json());
    }
    
    async getCheckedInRoute(featureOid: string,  routeType: RouteType, viewDate:string): Promise<IRoute> {
        const url = `${this.apiBaseUrl}/adminroute/getcheckedinroute?featureOid=${featureOid}&routeType=${routeType}&viewDate=${viewDate}`;

        return this.httpClient.fetch(url)
			.then(response => response.json());
    }

    async getCheckedOutRoute(featureOid: string,  routeType: RouteType, viewDate:string): Promise<IRoute> {
        const url = `${this.apiBaseUrl}/adminroute/getcheckedoutroute?featureOid=${featureOid}&routeType=${routeType}&viewDate=${viewDate}`;

        return this.httpClient.fetch(url)
			.then(response => response.json());
	}
	
	async merge(itemsToMerge: INetworkReferencesWithAction[], directedExtents: boolean): Promise<INetworkReferencesWithAction> {
		const url = `${this.apiBaseUrl}/adminroute/merge?directedExtents=${directedExtents}`;
        const config = {
            method: "POST",
            body: JSON.stringify(itemsToMerge),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
	}

    async getCheckedInRouteByLinkSequence(
        featureTypeId: number,
        linkSequenceOid: string,
        tvId: string,
        fromMeasure: number,
        toMeasure: number,
        viewDate: string): Promise<IRoute> {
        const url = `${this.apiBaseUrl}/adminroute/getcheckedinroutebylinksequence?featureTypeId=${featureTypeId}&linkSequenceOid=${linkSequenceOid}&tvId=${tvId}&fromMeasure=${fromMeasure}&toMeasure=${toMeasure}&viewDate=${viewDate}`;

        return this.httpClient.fetch(url)
			.then(response => response.json());
    }

    async getCheckedOutRouteByLinkSequence(
        featureTypeId: number,
        linkSequenceOid: string,
        tvId: string,
        fromMeasure: number,
        toMeasure: number,
        viewDate: string): Promise<IRoute> {
        const url = `${this.apiBaseUrl}/adminroute/getcheckedoutroutebylinksequence?featureTypeId=${featureTypeId}&linkSequenceOid=${linkSequenceOid}&tvId=${tvId}&fromMeasure=${fromMeasure}&toMeasure=${toMeasure}&viewDate=${viewDate}`;

        return this.httpClient.fetch(url)
			.then(response => response.json());
    }

    async undoCheckOut(route: IRoute): Promise<number> {
        const url = `${this.apiBaseUrl}/adminroute/undocheckout`;
        const config = {
            method: "POST",
            body: JSON.stringify(route),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
    }

    async deleteRoute(route: IRoute):Promise<number> {
        const url = `${this.apiBaseUrl}/adminroute/delete`;
        const config = {
            method: "POST",
            body: JSON.stringify(route),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
    }

    /**
     * Creates an empty route for given route type and view data
     * @param routeType 
     * @param viewDate 
     */
    newRoute(routeType: RouteType, viewDate: number): IRoute {
        const route: IRoute = {
            RouteType: routeType,
            RouteState: undefined,
            ValidFrom: viewDate,
            ValidTo: undefined,
            Comment: null,
            NetworkReferences: null,
            Geometry: null,
            Distance: null,
            Resistance: null,
            FeatureOid: null,
            FeatureTypeId: null,
            Id: null,
			IsCheckedIn: false
        };

        switch (routeType) {
            case RouteType.FacitRutt : {
                (route as unknown as IFacitRoute).Charterer = null;
                (route as IFacitRoute).Name = null;
                (route as IFacitRoute).PickupLocationName = null;
                (route as IFacitRoute).PickupLocationNumber = null;
                break;
            }
            case RouteType.RekommenderadLed : {
                (route as IRekRoute).Name = null;
                break;
            }
            case RouteType.TimmerLed : {
                (route as ITimberRoute).Name = null;
                break;
            }
            case RouteType.TpLed : {
                (route as ITPRoute).Direction = null;
                (route as ITPRoute).PickupLocationNumbers = null;
                (route as ITPRoute).MaxWeight = null;
                (route as ITPRoute).MinWeight = null;
                break;
            }
        }

        return route;
    }

}


export class DirectionMap {
    public static DirectionName = new Map<number, string>([
        [Direction.In, 'In'],
        [Direction.InOchUt, 'In och Ut'],
        [Direction.Ut, 'Ut']
    ]);
}
