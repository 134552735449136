import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { RouteType } from 'components/admin/admin-constants/index';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { IRouteHistory } from './models/route-history.interface';

@autoinject()
export class AdminRouteHistoryService {
    private apiBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private config: EnvironmentConfiguration) {

        this.apiBaseUrl = config.env.ApiBaseUrl;
    }

    async getHistory(featureOid: string, routeType: RouteType): Promise<IRouteHistory[]> {
        const url = `${this.apiBaseUrl}/AdminRouteHistory/GetHistory?featureOid=${featureOid}&routeType=${routeType}`;

        return this.httpClient.fetch(url)
            .then(response => response.json());
    }
}