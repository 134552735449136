import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';

@autoinject()
export class RouteDifferenceService{

	private apiBaseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private config: EnvironmentConfiguration){
		this.apiBaseUrl = config.env.ApiBaseUrl;
	}

	async getDifferences(fromDate:number, toDate:number): Promise<FacitDifference[]>{
		const url = `${this.apiBaseUrl}/difference/getdifferences?fromDate=${fromDate}&toDate=${toDate}`;
		return this.httpClient.fetch(url)
			.then(response => response.json());
	}

	async startTREControlJob(){
		const url = `${this.apiBaseUrl}/difference/starttrecontroljob`;
		return this.httpClient.fetch(url)
			.then(response => response);
	}
}
export class FacitDifference{
	Id:string;
	QuestionId: number;
	Name: string;
	NewRouteGeometry: string;
	OldRouteGeometry: string;
	Date:number;
	CostDifference:number;
	LengthDifference:number;
	Stop:boolean;
	RouteDifferences: Differences[];
}

export class Differences{
	Id: string;
	FacitDifferenceId: string;
	RouteId: string;
	Geometry:string;
	FromMeasure:number;
	ToMeasure:number;
	DifferenceList: Difference[];
}

export class Difference{
	DifferencesId:string;
	Property:string;
	OldValue:string;
	NewValue:string;
}