import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { DynamicBlockTypes } from '../admin-constants/index';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';

@autoinject()
export class DynamicBlocksService{

	private apiBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
		private config: EnvironmentConfiguration) {
        this.apiBaseUrl = config.env.ApiBaseUrl;
	}

	  /**
     * Creates an empty dynamicblock for given type and view data
     * @param dynamicBlockType
     */
    newDynamicBlock(dynamicBlockType: DynamicBlockTypes): IDynamicBlock {
		const dynamicblock: IDynamicBlock = {
			Id: null,
			Type: dynamicBlockType,
			ValidFrom: null,
			ValidTo: null,
			Comment: null,
			CheckedIn: false
        };

		switch(dynamicBlockType){
			case DynamicBlockTypes.Coord:{
				(dynamicblock as CoordDynamicblock).Coordblocks = [];
				break;
			}
			case DynamicBlockTypes.NVDB:{
				(dynamicblock as NvdbDynamicBlock).NvdbBlocks = [];
				break;
			}
			case DynamicBlockTypes.Polygon:{
				(dynamicblock as PolygonDynamicBlock).Polygonblocks = [];
				break;
			}
		}

        return dynamicblock;
	}
	
	saveDynamicBlock(dynamicblock:IDynamicBlock){
		const url = `${this.apiBaseUrl}/dynamicblock/CreateDynamicBlock`;
		const config = {
			method: "POST",
			body: JSON.stringify(dynamicblock),
			headers: { 'Content-Type': 'application/json' }
		};
		return this.httpClient.fetch(url, config).then(x => x.json());
	}

	updateDynamicBlock(dynamicblock: IDynamicBlock) {
		const url = `${this.apiBaseUrl}/dynamicblock/UpdateDynamicBlock?id=${dynamicblock.Id}`;
        const config = {
			method: "PUT",
			body: JSON.stringify(dynamicblock),
            headers : {'Content-Type': 'application/json'}
        };
        return this.httpClient.fetch(url, config);
	}

	deleteDynamicBlock(Id: string) {
		const url = `${this.apiBaseUrl}/dynamicblock/DeleteDynamicBlock?id=${Id}`;
        const config = {
            method: "PUT",
            headers : {'Content-Type': 'application/json'}
        };
        return this.httpClient.fetch(url, config);
	}

	async getDynamicBlocks(): Promise<DynamicBlockList> {
        const url = `${this.apiBaseUrl}/dynamicblock/getdynamicblocks`;
	
        return this.httpClient.fetch(url)
            .then(response => response.json());
	}

	async getDynamicBlock(id: string): Promise<IDynamicBlock>{
		const url = `${this.apiBaseUrl}/dynamicblock/GetDynamicBlock?id=${id}`;
		return this.httpClient.fetch(url).then(response => response.json());
	}

	async checkInDynamicBlock(Id: string) {
		const url = `${this.apiBaseUrl}/dynamicblock/CheckInDynamicBlock?id=${Id}`;
        const config = {
            method: "PUT",
            headers : {'Content-Type': 'application/json'}
        };
        return this.httpClient.fetch(url, config);
    };

	async getLinkSequencesGeometryByCoord(radius: number, point: string): Promise<string[]>{
		const url = `${this.apiBaseUrl}/dynamicblock/GetLinkSequencesGeometryByCoord?radius=${radius}&point=${point}`;
		return this.httpClient.fetch(url).then(response => response.json());
	}

	async getLinkSequencesGeometryByPolygon(bbox:string): Promise<string[]>{
		const url = `${this.apiBaseUrl}/dynamicblock/GetLinkSequencesGeometryByPolygon?bbox=${bbox}`;
		return this.httpClient.fetch(url).then(response => response.json());
	}
}


export class DynamicBlockList{
	DynamicblockItems: IDynamicBlock[]
}

export class IDynamicBlock
{
	Id:string;
	Type:DynamicBlockTypes;
	ValidFrom:number;
	ValidTo:number;
	Comment:string;
	CheckedIn: boolean;
}

export class IBlocks{
	DynamicBlockId:string;
}

export class Nvdb extends IBlocks
{
	RouteId:string;
	Measure:string;
	Delay:string;
	Direction:string;
}

export class Polygon extends IBlocks{
	WKT:string;
	Delay:string;
	Direction:string;
}

export class Coord extends IBlocks{
	X:string;
	Y:string;
	Radius:number;
	Delay:string;
	Direction:string;
}

export class CoordDynamicblock extends IDynamicBlock{
	Coordblocks: Coord[];
}

export class NvdbDynamicBlock extends IDynamicBlock{
	NvdbBlocks: Nvdb[];
}

export class PolygonDynamicBlock extends IDynamicBlock{
	Polygonblocks: Polygon[];
}


